import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  TextField,
  Typography,
  Grid,
  Paper,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem, Alert, Snackbar, CircularProgress
} from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { loadStripe } from '@stripe/stripe-js';
import { Elements, useStripe, useElements, CardElement } from '@stripe/react-stripe-js';
import DeleteIcon from '@mui/icons-material/Delete';
import countries from 'i18n-iso-countries';
import en from 'i18n-iso-countries/langs/en.json'; // Import English country names
import { taxIdTypes } from './taxIdTypes';

// Import SVG icons from the payment-icons package
import visa from 'payment-icons/min/flat/visa.svg';
import mastercard from 'payment-icons/min/flat/mastercard.svg';
import amex from 'payment-icons/min/flat/amex.svg';
import discover from 'payment-icons/min/flat/discover.svg';
import jcb from 'payment-icons/min/flat/jcb.svg';
import diners from 'payment-icons/min/flat/diners.svg';
import unionpay from 'payment-icons/min/flat/unionpay.svg';


const stripePromise = loadStripe('pk_live_51PQyWYLZTDtsWuBgLhOejryhdBx7anMlwY2bB2Y63tti6IcB10vxzQcX53z44z753bMj2OIKacCjoDgHhZLsiAlF00386zBZNJ'); // Replace with your Stripe publishable key

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
    primary: { main: '#3f51b5' },
    background: { default: '#121212', paper: '#1E1E1E' },
    text: { primary: '#ffffff', secondary: '#aaaaaa' },
  },
});

// Register the language for country names
countries.registerLocale(en);

// Tax ID types with regex patterns

const baseendpoint = "https://api.cubedai.com";


const CustomerPreferences = ({ onPreferencesSaved, existingPreferences }) => {
  const [preferences, setPreferences] = useState({
    company_name: '',
    billing_email: '',
    country: '',
    address_1: '',
    address_2: '',
    city: '',
    postcode: '',
    tax_id: '',
    tax_id_type: '',
    tax_id_country: '',
    state:''
  });

  const [cardInfo, setCardInfo] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [taxIdError, setTaxIdError] = useState(null);
  const [countryList, setCountryList] = useState([]);
  const [selectedTaxIdFormat, setSelectedTaxIdFormat] = useState('');

  useEffect(() => {
    const loadCountries = () => {
      const countriesObj = countries.getNames('en'); // Get country names in English
      const countryArray = Object.entries(countriesObj).map(([code, name]) => ({
        code,
        name,
      }));
      setCountryList(countryArray);
    };
    loadCountries();
    
    // Fetch the saved billing information
    const fetchBillingInformation = async () => {
      const token = localStorage.getItem('token');
      try {
        const response = await fetch(`${baseendpoint}/user/payment/information`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        });

        if (response.ok) {
          const data = await response.json();
          setPreferences({
            company_name: data.company_name || '',
            billing_email: data.billing_email || '',
            country: data.country || '',
            address_1: data.address_1 || '',
            address_2: data.address_2 || '',
            city: data.city || '',
            postcode: data.postcode || '',
            tax_id: data.tax_id || '',
            tax_id_type: data.tax_id_type || '',
            tax_id_country: data.tax_id_country || ''
          });
          setCardInfo({
            last4: data.last4,
            exp_month: data.exp_month,
            exp_year: data.exp_year,
            brand: data.brand,
          });
        } else if (response.status === 404) {
          setErrorMessage('No billing information found for this user.');
        } else {
          setErrorMessage('Failed to load billing information.');
        }
      } catch (error) {
        setErrorMessage('Error fetching billing information.');
      }
    };

    fetchBillingInformation();
  }, []);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setPreferences((prev) => ({ ...prev, [name]: value }));
  };

  const handleTaxIdTypeChange = (event) => {
    const selectedType = event.target.value;
  
    const selectedTaxId = taxIdTypes.find((taxId) => taxId.country === selectedType);
    if (selectedTaxId) {
      // Update the preferences with both tax_id_type and tax_id_country
      setPreferences((prev) => ({
        ...prev,
        tax_id_country: selectedTaxId.country,
        tax_id_type: selectedTaxId.type,  // Ensure this is properly set
      }));
      setSelectedTaxIdFormat(selectedTaxId.format);
    } else {
      setSelectedTaxIdFormat('');
    }
  };

  const handleSubmit = async () => {


    const requiredFields = [
      { field: 'company_name', label: 'Company Name' },
      { field: 'billing_email', label: 'Billing Email' },
      { field: 'country', label: 'Country' },
      { field: 'address_1', label: 'Address Line 1' },
      { field: 'city', label: 'City' },
      { field: 'postcode', label: 'Postcode' },
    ];
  
    // Check for missing required fields
    const missingFields = requiredFields.filter(
      (item) => !preferences[item.field]
    );
  
    if (missingFields.length > 0) {
      const fieldNames = missingFields.map((item) => item.label).join(', ');
      setErrorMessage(`The following fields are required: ${fieldNames}`);
      return;
    } else {
      setErrorMessage(null);
    }
    const selectedTaxId = taxIdTypes.find((taxId) => taxId.country === preferences.tax_id_country);

    if (preferences.tax_id && selectedTaxId && !selectedTaxId.regex.test(preferences.tax_id)) {
      setTaxIdError(`Invalid Tax ID format. Expected: ${selectedTaxId.format}`);
      return;
    } else {
      setTaxIdError(null);
    }

    const token = localStorage.getItem('token');
    try {
      const response = await fetch(`${baseendpoint}/user/payment/information`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        
        body: JSON.stringify(preferences),
      });
      const result = await response.json();
      if (response.ok) {
        onPreferencesSaved(result);
        setSuccessMessage('Preferences saved successfully');
        setErrorMessage(null);
        setTimeout(() => setSuccessMessage(null), 3000);
      } else {
        setErrorMessage('Failed to save preferences. Please try again.');
        setSuccessMessage(null);
      }
    } catch (error) {
      setErrorMessage('Error saving preferences. Please try again later.');
      setSuccessMessage(null);
    }
  };

  return (
    <Paper sx={{ padding: 3, marginBottom: 3, backgroundColor: 'background.paper' }}>
      <Typography variant="h6" gutterBottom color="primary">
        Customer Preferences
      </Typography>

      {successMessage && (
        <Alert severity="success" sx={{ mb: 2 }}>
          {successMessage}
        </Alert>
      )}

      {errorMessage && (
        <Alert severity="error" sx={{ mb: 2 }}>
          {errorMessage}
        </Alert>
      )}

      {taxIdError && (
        <Alert severity="error" sx={{ mb: 2 }}>
          {taxIdError}
        </Alert>
      )}

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Company Name"
            variant="outlined"
            name="company_name"
            value={preferences.company_name}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Billing Email"
            variant="outlined"
            name="billing_email"
            value={preferences.billing_email}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            select
            label="Country"
            variant="outlined"
            name="country"
            value={preferences.country}
            onChange={handleChange}
          >
            {countryList.map((country) => (
              <MenuItem key={country.code} value={country.code}>
                {country.name}
              </MenuItem>
            ))}
          </TextField>
        </Grid>

        <Grid item xs={12} container spacing={2}>
          <Grid item xs={4}>
            <TextField
              fullWidth
              select
              label="Tax ID Type"
              variant="outlined"
              name="tax_id_type"
              value={preferences.tax_id_country}
              onChange={handleTaxIdTypeChange}
            >
              {taxIdTypes.map((taxId) => (
                <MenuItem key={taxId.country} value={taxId.country}>
                  {taxId.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={8}>
            <TextField
              fullWidth
              label="Tax ID"
              variant="outlined"
              name="tax_id"
              value={preferences.tax_id}
              onChange={handleChange}
              helperText={selectedTaxIdFormat ? `Accepted format: ${selectedTaxIdFormat}` : ''}
              error={!!taxIdError}
            />
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Address Line 1"
            variant="outlined"
            name="address_1"
            value={preferences.address_1}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Address Line 2"
            variant="outlined"
            name="address_2"
            value={preferences.address_2}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            label="City"
            variant="outlined"
            name="city"
            value={preferences.city}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            label="Postcode"
            variant="outlined"
            name="postcode"
            value={preferences.postcode}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="State"
            variant="outlined"
            name="state"
            value={preferences.state}
            onChange={handleChange}
          />
        </Grid>
      </Grid>

      <Button variant="contained" onClick={handleSubmit} sx={{ mt: 2 }}>
        Save Preferences
      </Button>

      
    </Paper>
  );
};

// Map Stripe card brands to the corresponding SVG paths
const brandLogoMap = {
  visa: visa,
  mastercard: mastercard,
  amex: amex,
  discover: discover,
  jcb: jcb,
  diners: diners,
  unionpay: unionpay,
};

const BillingCard = ({ cardInfo, onDelete}) => {
  // Normalize the Stripe card brand to lowercase
  const [errorMessage, setErrorMessage] = useState(null);
  const brand = cardInfo.brand.toLowerCase();

  // Get the correct SVG or fallback to the generic credit icon
  const brandLogo = brandLogoMap[brand];

  // Function to handle the delete action
  const handleDelete = async () => {
    try {
      const token = localStorage.getItem('token'); // Retrieve the token from localStorage

      if (!token) {
        console.error('No authorization token found');
        return;
      }

      // Make the API request to delete the payment method
      const response = await fetch(`${baseendpoint}/user/payment/delete-payment-method`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`, // Include the token in the Authorization header
        }
      });

      if (response.ok) {
        // Call onDelete callback to update the UI after successful deletion
        onDelete();
      } else {
        setErrorMessage('Failed to delete the payment method');
      }
    } catch (error) {
      setErrorMessage('Error deleting payment method');
    }
  };

  return (
    <Box sx={{ marginTop: 3 }}>
      {errorMessage && (
        <Alert severity="error" sx={{ mb: 2 }}>
          {errorMessage}
        </Alert>
      )}
      <Paper sx={{ padding: 2, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Box display="flex" alignItems="center">
          {/* Render the payment icon as an image */}
          <img src={brandLogo} alt={cardInfo.brand} style={{ width: 40, height: 25, marginRight: 10 }} />

          <Box>
            <Typography variant="body1">
              •••• {cardInfo.last4} Expires {cardInfo.exp_month}/{cardInfo.exp_year}
            </Typography>
            <Typography variant="body2" color="textSecondary">
              Default
            </Typography>
          </Box>
        </Box>

        <Button onClick={handleDelete} color="error">
          <DeleteIcon />
        </Button>
      </Paper>
    </Box>
  );
};

// Stripe Payment Form Component
const StripeForm = ({ onCardAdded, closeForm, customerId }) => {  // Added customerId as a parameter
  const stripe = useStripe();
  const elements = useElements();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);

  const [billingDetails, setBillingDetails] = useState({
    name: '',
    country: '',
    addressLine1: '',
    addressLine2: '',
    city: '',
    postalCode: '',
    state: '',
  });

  const [fieldErrors, setFieldErrors] = useState({});
  const [countryList, setCountryList] = useState([]);

  useEffect(() => {
    const loadCountryList = () => {
      const countriesObj = countries.getNames('en'); 
      const countryArray = Object.entries(countriesObj).map(([code, name]) => ({
        code,
        name,
      }));
      setCountryList(countryArray);
    };
    loadCountryList();
  }, []);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setBillingDetails((prev) => ({ ...prev, [name]: value }));
  };

  const validateFields = () => {
    const errors = {};
    if (!billingDetails.name) errors.name = 'Name on card is required';
    if (!billingDetails.country) errors.country = 'Country is required';
    if (!billingDetails.addressLine1) errors.addressLine1 = 'Address Line 1 is required';
    if (!billingDetails.city) errors.city = 'City is required';
    if (!billingDetails.postalCode) errors.postalCode = 'Postal Code is required';
    setFieldErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!stripe || !elements) return;

    if (!validateFields()) return;

    setLoading(true);
    const cardElement = elements.getElement(CardElement);

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
      billing_details: {
        name: billingDetails.name,
        address: {
          line1: billingDetails.addressLine1,
          line2: billingDetails.addressLine2,
          city: billingDetails.city,
          postal_code: billingDetails.postalCode,
          country: billingDetails.country,
          state: billingDetails.state,
        },
      },
    });

    if (error) {
      setError(error.message);
    } else {
      try {

        // Example API call to save the payment method on your backend
        const token = localStorage.getItem('token');
        const response = await fetch(`${baseendpoint}/user/payment/save-payment-method`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            paymentMethodId: paymentMethod.id,
            customerId: customerId,
            last4: paymentMethod.card.last4,
            exp_month: paymentMethod.card.exp_month,
            exp_year: paymentMethod.card.exp_year,
            brand: paymentMethod.card.brand,
          }),
        });

        const result = await response.json();
        if (response.ok) {
          setSuccessMessage('Card added successfully!');
          onCardAdded(paymentMethod.card);
          setTimeout(() => {
            setSuccessMessage(null);
            closeForm();
          }, 2000);
        } else {
          setError(result.error || 'Failed to save payment method');
        }
      } catch (e) {
        setError('Failed to attach payment method');
      }
    }

    setLoading(false);
  };

  return (
    <>
      {successMessage && <Alert severity="success">{successMessage}</Alert>}
      {error && <Alert severity="error">{error}</Alert>}
      
      <form onSubmit={handleSubmit}>
        <TextField
          fullWidth
          label="Name on card"
          variant="outlined"
          value={billingDetails.name}
          name="name"
          onChange={handleChange}
          sx={{ mb: 2 }}
          error={!!fieldErrors.name}
          helperText={fieldErrors.name}
        />
        <CardElement options={{ style: { base: { fontSize: '16px', color: '#ffffff' } } , hidePostalCode: true}} />
        <Box sx={{ mt: 2 }}>
          <TextField
            fullWidth
            select
            label="Country"
            variant="outlined"
            name="country"
            value={billingDetails.country}
            onChange={handleChange}
            sx={{ mb: 2 }}
            error={!!fieldErrors.country}
            helperText={fieldErrors.country}
          >
            {countryList.map((country) => (
              <MenuItem key={country.code} value={country.code}>
                {country.name}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            fullWidth
            label="Address line 1"
            variant="outlined"
            value={billingDetails.addressLine1}
            name="addressLine1"
            onChange={handleChange}
            sx={{ mb: 2 }}
            error={!!fieldErrors.addressLine1}
            helperText={fieldErrors.addressLine1}
          />
          <TextField
            fullWidth
            label="Address line 2"
            variant="outlined"
            value={billingDetails.addressLine2}
            name="addressLine2"
            onChange={handleChange}
            sx={{ mb: 2 }}
          />
          <Grid container spacing={2} sx={{ mb: 2 }}>
            <Grid item xs={6}>
              <TextField
                fullWidth
                label="City"
                variant="outlined"
                value={billingDetails.city}
                name="city"
                onChange={handleChange}
                error={!!fieldErrors.city}
                helperText={fieldErrors.city}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                label="Postal Code"
                variant="outlined"
                value={billingDetails.postalCode}
                name="postalCode"
                onChange={handleChange}
                error={!!fieldErrors.postalCode}
                helperText={fieldErrors.postalCode}
              />
            </Grid>
          </Grid>
          <TextField
            fullWidth
            label="State"
            variant="outlined"
            value={billingDetails.state}
            name="state"
            onChange={handleChange}
            sx={{ mb: 2 }}
            error={!!fieldErrors.state}
            helperText={fieldErrors.state}
          />
        </Box>
        <Button type="submit" disabled={!stripe || loading} variant="contained" sx={{ mt: 2 }}>
          {loading ? 'Processing...' : 'Save Card'}
        </Button>
      </form>
    </>
  );
};

const UserProfilePage = () => {
  const [user, setUser] = useState({
    name: '',
    email: '',
    creditBalance: 0,
  });

  const [cardInfo, setCardInfo] = useState(null);
  const [addCardDialogOpen, setAddCardDialogOpen] = useState(false);
  const [successMessage, setSuccessMessage] = useState(null);  // Success message state
  const [preferences, setPreferences] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [customerId, setCustomerId] = useState(null);  // New state for customerId
  const [addCreditDialogOpen, setAddCreditDialogOpen] = useState(false);
  const [creditBalance, setCreditBalance] = useState(user.creditBalance);

  useEffect(() => {
    const fetchUserPreferences = async () => {
      const token = localStorage.getItem('token');
      try {
        const response = await fetch(`${baseendpoint}/user/payment/information`, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });
        if (response.ok) {
          const data = await response.json();
          
          setPreferences(data);
          setUser((prev) => ({
            ...prev,

          }));
          if (data.balance){
            setCreditBalance(data.balance)
          } else{
            setCreditBalance(0)
          }
          

        } else {
          console.error('Failed to retrieve user preferences');
        }
      } catch (error) {
        console.error('Error fetching user preferences:', error);
      }
    };
    fetchUserPreferences();
  }, []);

  const handlePreferencesSaved = (data) => {
    setPreferences(data);
  };

  // Fetch the saved billing information
  const fetchCardInformation = async () => {
    const token = localStorage.getItem('token');
    try {
      const response = await fetch(`${baseendpoint}/user/payment/information`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.ok) {
      
        const data = await response.json();
          if (data.payment_method){
            setCardInfo({
              last4: data.last4,
              exp_month: data.exp_month,
              exp_year: data.exp_year,
              brand: data.brand,
            });
          }

      } else if (response.status === 404) {
        setErrorMessage('No billing information found for this user.');
      } else {
        setErrorMessage('Failed to load billing information.');
      }
    } catch (error) {
      setErrorMessage('Error fetching billing information.');
    }
  };
  useEffect(() => {
    fetchCardInformation();
  }, []);  // Fetch card info when the component mounts

  
  const [creditAmount, setCreditAmount] = useState(0);
  const [error, setError] = useState('');
  const [tax, setTax] = useState(null); // Store tax value
  const [totalPrice, setTotalPrice] = useState(null); // Store total price value
  const [tax_id, setTax_id] = useState('');
  const [taxRate, setTaxRate] = useState('')
  const [showSummaryDialog, setShowSummaryDialog] = useState(false); // To control second dialog
  const [showSnackbar, setShowSnackbar] = useState(false); // To show success message
  const [paymentSuccess, setPaymentSuccess] = useState(false); // Track payment success
  const [showSnackbarCredit, setShowSnackbarCredit] = useState(false); //it is for add credit

  const [loading, setLoading] = useState(false);
  


  const resetState = () => {
    setCreditAmount(0);
    setError('');
    setTax(null);
    setTax_id('');
    setTaxRate('');
    setTotalPrice(null);
    setShowSummaryDialog(false);
    setPaymentSuccess(false);
  };

  const handleAddCredit = async () => {
    if (creditAmount < 10) {
      setErrorMessage('Please enter a valid amount');
      return;
    }

    try {
      const token = localStorage.getItem('token');
      // Create a request to fetch tax and total
      const response = await fetch(`${baseendpoint}/payments/calculate_tax`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        body: JSON.stringify({
          amount: creditAmount,  // Credit amount in dollars
        }),
      });

      if (!response.ok) {
        setErrorMessage('Failed to finish payment');
      }

      const data = await response.json();
      setTax(data.tax); // Assuming tax is returned in the response
      setTotalPrice(data.total_amount); // Assuming total price is returned
      setTax_id(data.tax_id);
      setTaxRate(data.tax_rate);
      setAddCreditDialogOpen(false); // Close the first dialog
      setShowSummaryDialog(true); // Open the second dialog to show summary
    } catch (error) {
      setErrorMessage('Failed to finish payment');
    }finally{
      
    }
  };

  const handleConfirmPayment = async () => {
    setLoading(true);
    setErrorMessage(''); // Reset any error message
    setSuccessMessage(''); // Reset any success message
    try {
      const token = localStorage.getItem('token');
      // Request to complete payment
      const response = await fetch(`${baseendpoint}/payments/complete_payment`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        body: JSON.stringify({
          amount: totalPrice,
          tax_id: tax_id,
          tax_rate: taxRate,
          base_amount:creditAmount
        }),
      });
      if (!response.ok) {
        setErrorMessage('Failed to finish payment');
      }

      const paymentResult = await response.json();

      // Check if payment status is succeeded
      if (paymentResult.status === 'paid') {
        setPaymentSuccess(true);
        setShowSnackbar(true); // Show success message
        setCreditBalance(paymentResult.balance)

      }
      setShowSummaryDialog(false); // Close the summary dialog on successful payment
      resetState(); // Reset state after successful payment

    } catch (error) {
      setErrorMessage('Failed to finish payment');
    }finally{
      setLoading(false);
    }
  };



  const handleCardAdded = (newCardInfo) => {
    setCardInfo({
      last4: newCardInfo.last4,
      exp_month: newCardInfo.exp_month,
      exp_year: newCardInfo.exp_year,
      brand: newCardInfo.brand,
    });

    setAddCardDialogOpen(false);  // Close the dialog
    setSuccessMessage('Card added successfully!');  // Show success message
    setTimeout(() => setSuccessMessage(null), 3000);  // Clear success message after 3 seconds
  };

  const fetchUserInformation = async () => {
    const token = localStorage.getItem('token');
    try {
      const response = await fetch(`${baseendpoint}/users/user-info`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.ok) {
        const data = await response.json();
        setUser({
          name: data.name,
          email: data.email
        });
        
      } else if (response.status === 404) {
        setErrorMessage('No profile information found for this user.');
      } else {
        setErrorMessage('Failed to load user information.');
      }
    } catch (error) {
      setErrorMessage('Error fetching user information.');
    }
  };
  useEffect(() => {
    fetchUserInformation();
  }, []);  // Fetch card info when the component mounts


  // Function to handle Add Credit button click
  const handleAddCreditClick = () => {
    if (!cardInfo) {
      // If no cardInfo, show Snackbar
      setShowSnackbarCredit(true);
    } else {
      // If cardInfo exists, open Add Credit dialog
      setAddCreditDialogOpen(true);
    }
  };

  return (
    <Box sx={{ padding: 4, maxWidth: 900, margin: '0 auto' }}>
      <Typography variant="h4" gutterBottom>
        User Profile
      </Typography>

      {/* Success Message */}
      {successMessage && (
        <Typography variant="body1" color="green">
          {successMessage}
        </Typography>
      )}
      {errorMessage && (
        <Alert severity="error" sx={{ mb: 2 }}>
          {errorMessage}
        </Alert>
      )}

      {/* User Information Section */}
      <Paper sx={{ padding: 3, marginBottom: 3 }}>
        <Typography variant="h6" gutterBottom>
          User Information
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Email"
              variant="outlined"
              value={user.email}
              disabled
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Name"
              variant="outlined"
              value={user.name}
              disabled
            />
          </Grid>
        </Grid>
      </Paper>

      {/* Credit Information Section */}
      <Paper sx={{ padding: 3, marginBottom: 3 }}>
        <Typography variant="h6" gutterBottom color="primary">
          Current Credit Balance: €{creditBalance.toFixed(2)}
        </Typography>
        <Button variant="contained" color="primary" onClick={() => handleAddCreditClick(true)}>
          Add Credit
        </Button>
        <Typography
          variant="body2"
          style={{
            color: '#666', // Customize the color
            fontSize: '0.8rem', // Customize the size
            marginTop: 20,
          }}
        >
          Min. amount is 10€
        </Typography>

      </Paper>

      <Snackbar
        open={showSnackbarCredit}
        autoHideDuration={6000} // Close after 6 seconds
        onClose={() => setShowSnackbarCredit(false)}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert onClose={() => setShowSnackbarCredit(false)} severity="warning" sx={{ width: '100%' }}>
          No payment method available. Please add a card.
        </Alert>
      </Snackbar>

      <Dialog open={addCreditDialogOpen} onClose={() => {
        setAddCreditDialogOpen(false);
        resetState(); // Reset state when the first dialog is closed
      }}>
        <DialogTitle>Add Credit</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Amount"
            type="number"
            fullWidth
            value={creditAmount}
            onChange={(e) => {
              setCreditAmount(parseFloat(e.target.value));
              setError(''); // Clear error when user updates input
            }}
            error={!!error}
            helperText={error}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => {
            setAddCreditDialogOpen(false);
            resetState(); // Reset state when cancelling
          }} color="primary">
            Cancel
          </Button>
          <Button onClick={handleAddCredit} color="primary">
            Add Credit
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
      open={showSummaryDialog}
      onClose={() => {
        setShowSummaryDialog(false);
        resetState(); // Reset state when the summary dialog is closed
        setErrorMessage('');
        setSuccessMessage('');
      }}
    >
      <DialogTitle>Confirm Payment</DialogTitle>
      <DialogContent>
        {/* Display non-editable amount, tax, and total */}
        <Typography variant="body1">Amount: €{creditAmount.toFixed(2)}</Typography>
        <Typography variant="body1">VAT: €{tax ? tax.toFixed(2) : ''}</Typography>
        <Typography variant="body1">Total: €{totalPrice ? totalPrice.toFixed(2) : ''}</Typography>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            setShowSummaryDialog(false);
            resetState(); // Reset state when cancelling the summary dialog
            setErrorMessage('');
            setSuccessMessage('');
          }}
          color="primary"
          disabled={loading}
        >
          Cancel
        </Button>
        <Button onClick={handleConfirmPayment} color="primary" disabled={loading} style={{ minWidth: '120px' }}>
          {loading ? <CircularProgress size={20} /> : 'Confirm Payment'}
        </Button>
      </DialogActions>
      <DialogContent>
        
        {/* Powered by Stripe text with styling */}
        <Typography
          variant="body2"
          style={{
            color: '#666', // Customize the color
            fontSize: '0.8rem', // Customize the size
            textAlign: 'center',
            marginTop: 20,
          }}
        >
          Powered by Stripe
        </Typography>
      </DialogContent>
    </Dialog>

      {/* Snackbar for showing success message */}
      <Snackbar
        open={showSnackbar}
        autoHideDuration={6000} // Close after 6 seconds
        onClose={() => setShowSnackbar(false)}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert onClose={() => setShowSnackbar(false)} severity="success" sx={{ width: '100%' }}>
          Payment was successful!
        </Alert>
      </Snackbar>


      {/* Customer Preferences Section */}
      <CustomerPreferences onPreferencesSaved={handlePreferencesSaved} existingPreferences={preferences} />


      {/* Billing Information Section */}
      <Paper sx={{ padding: 3, marginBottom: 3 }}>
        <Typography variant="h6" gutterBottom>
          Billing Information
        </Typography>

        {cardInfo ? (
          <BillingCard cardInfo={cardInfo} onDelete={() => setCardInfo(null)} />
        ) : (
          <Typography>No payment method added</Typography>
        )}

        <Button
          variant="outlined"
          onClick={() => setAddCardDialogOpen(true)}
          sx={{ mt: 2 }}
          disabled={!preferences || !preferences.customer_id}  // Check if preferences exists and has customer_id
        >
          {cardInfo ? 'Update Payment Method' : 'Add Payment Method'}
        </Button>
      </Paper>

      {/* Add Payment Method Dialog */}
      <Dialog open={addCardDialogOpen} onClose={() => setAddCardDialogOpen(false)} fullWidth>
      <DialogTitle>Add Payment Method</DialogTitle>
      <DialogContent>
        <Elements stripe={stripePromise}>
          {preferences && preferences.customer_id ? (
            <StripeForm
              onCardAdded={handleCardAdded}
              closeForm={() => setAddCardDialogOpen(false)}
              customerId={preferences.customer_id}  // Pass customerId safely
            />
          ) : (
            <Typography variant="body2" color="error">
              No customer ID found. Please try again later or contact support.
            </Typography>
          )}
        </Elements>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setAddCardDialogOpen(false)}>Cancel</Button>
      </DialogActions>
    </Dialog>
    </Box>
  );
};

export default UserProfilePage;
