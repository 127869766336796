export const taxIdTypes = [
    { country: 'Australia', type: 'au_abn', label: 'AU ABN', format: '12345678912', regex: /^\d{11}$/ },
    { country: 'Austria', type: 'eu_vat', label: 'AT VAT', format: 'ATU12345678', regex: /^ATU\d{8}$/ },
    { country: 'Bahrain', type: 'bh_vat', label: 'BH VAT', format: '123456789012345', regex: /^\d{15}$/ },
    { country: 'Belgium', type: 'eu_vat', label: 'BE VAT', format: 'BE0123456789', regex: /^BE0?\d{9}$/ },
    { country: 'Bulgaria', type: 'eu_vat', label: 'BG VAT', format: 'BG0123456789', regex: /^BG\d{9,10}$/ },
    { country: 'Canada', type: 'ca_bn', label: 'CA BN', format: '123456789', regex: /^\d{9}$/ },
    { country: 'Canada', type: 'ca_gst_hst', label: 'CA GST/HST', format: '123456789RT0002', regex: /^\d{9}RT\d{4}$/ },
    { country: 'Canada', type: 'ca_pst_bc', label: 'CA PST (BC)', format: 'PST-1234-5678', regex: /^PST-\d{4}-\d{4}$/ },
    { country: 'Canada', type: 'ca_pst_mb', label: 'CA PST (MB)', format: '123456-7', regex: /^\d{6}-\d{1}$/ },
    { country: 'Canada', type: 'ca_pst_sk', label: 'CA PST (SK)', format: '1234567', regex: /^\d{7}$/ },
    { country: 'Canada', type: 'ca_qst', label: 'CA QST', format: '1234567890TQ1234', regex: /^\d{10}TQ\d{4}$/ },
    { country: 'Chile', type: 'cl_tin', label: 'CL TIN', format: '12.345.678-K', regex: /^\d{2}\.\d{3}\.\d{3}-[K\d]{1}$/ },
    { country: 'Croatia', type: 'eu_vat', label: 'HR VAT', format: 'HR12345678912', regex: /^HR\d{11}$/ },
    { country: 'Cyprus', type: 'eu_vat', label: 'CY VAT', format: 'CY12345678Z', regex: /^CY\d{8}[A-Z]$/ },
    { country: 'Czech Republic', type: 'eu_vat', label: 'CZ VAT', format: 'CZ1234567890', regex: /^CZ\d{8,10}$/ },
    { country: 'Denmark', type: 'eu_vat', label: 'DK VAT', format: 'DK12345678', regex: /^DK\d{8}$/ },
    { country: 'Egypt', type: 'eg_tin', label: 'EG TIN', format: '123456789', regex: /^\d{9}$/ },
    { country: 'Estonia', type: 'eu_vat', label: 'EE VAT', format: 'EE123456789', regex: /^EE\d{9}$/ },
    { country: 'Finland', type: 'eu_vat', label: 'FI VAT', format: 'FI12345678', regex: /^FI\d{8}$/ },
    { country: 'France', type: 'eu_vat', label: 'FR VAT', format: 'FRAB123456789', regex: /^FR[A-HJ-NP-Z0-9]{2}\d{9}$/ },
    { country: 'Georgia', type: 'ge_vat', label: 'GE VAT', format: '123456789', regex: /^\d{9}$/ },
    { country: 'Germany', type: 'eu_vat', label: 'DE VAT', format: 'DE123456789', regex: /^DE\d{9}$/ },
    { country: 'Greece', type: 'eu_vat', label: 'EL VAT', format: 'EL123456789', regex: /^EL\d{9}$/ },
    { country: 'Hungary', type: 'eu_vat', label: 'HU VAT', format: 'HU12345678', regex: /^HU\d{8}$/ },
    { country: 'Iceland', type: 'is_vat', label: 'IS VAT', format: '123456', regex: /^\d{6}$/ },
    { country: 'India', type: 'in_gst', label: 'IN GST', format: '12ABCDE3456FGZH', regex: /^\d{2}[A-Z]{5}\d{4}[A-Z]{1}[A-Z\d]{1}[Z]{1}[A-Z\d]{1}$/ },
    { country: 'Ireland', type: 'eu_vat', label: 'IE VAT', format: 'IE1234567AB', regex: /^IE\d{7}[A-Z]{1,2}$/ },
    { country: 'Italy', type: 'eu_vat', label: 'IT VAT', format: 'IT12345678912', regex: /^IT\d{11}$/ },
    { country: 'Kazakhstan', type: 'kz_bin', label: 'KZ BIN', format: '123456789012', regex: /^\d{12}$/ },
    { country: 'Kenya', type: 'ke_pin', label: 'KE PIN', format: 'P000111111A', regex: /^P\d{9}[A-Z]$/ },
    { country: 'Latvia', type: 'eu_vat', label: 'LV VAT', format: 'LV12345678912', regex: /^LV\d{11}$/ },
    { country: 'Lithuania', type: 'eu_vat', label: 'LT VAT', format: 'LT123456789123', regex: /^LT\d{9,12}$/ },
    { country: 'Luxembourg', type: 'eu_vat', label: 'LU VAT', format: 'LU12345678', regex: /^LU\d{8}$/ },
    { country: 'Malta', type: 'eu_vat', label: 'MT VAT', format: 'MT12345678', regex: /^MT\d{8}$/ },
    { country: 'Mexico', type: 'mx_rfc', label: 'MX RFC', format: 'ABC010203AB9', regex: /^[A-Z]{3}\d{6}[A-Z\d]{3}$/ },
    { country: 'Netherlands', type: 'eu_vat', label: 'NL VAT', format: 'NL123456789B12', regex: /^NL\d{9}B\d{2}$/ },
    { country: 'New Zealand', type: 'nz_gst', label: 'NZ GST', format: '123456789', regex: /^\d{9}$/ },
    { country: 'Nigeria', type: 'ng_tin', label: 'NG TIN', format: '12345678-0001', regex: /^\d{8}-\d{4}$/ },
    { country: 'Norway', type: 'no_vat', label: 'NO VAT', format: '123456789MVA', regex: /^\d{9}MVA$/ },
    { country: 'Oman', type: 'om_vat', label: 'OM VAT', format: 'OM1234567890', regex: /^OM\d{10}$/ },
    { country: 'Poland', type: 'eu_vat', label: 'PL VAT', format: 'PL1234567890', regex: /^PL\d{10}$/ },
    { country: 'Portugal', type: 'eu_vat', label: 'PT VAT', format: 'PT123456789', regex: /^PT\d{9}$/ },
    { country: 'Romania', type: 'eu_vat', label: 'RO VAT', format: 'RO1234567891', regex: /^RO\d{2,10}$/ },
    { country: 'Saudi Arabia', type: 'sa_vat', label: 'SA VAT', format: '123456789012345', regex: /^\d{15}$/ },
    { country: 'Singapore', type: 'sg_gst', label: 'SG GST', format: 'M12345678X', regex: /^M\d{8}[A-Z]$/ },
    { country: 'Slovakia', type: 'eu_vat', label: 'SK VAT', format: 'SK1234567891', regex: /^SK\d{10}$/ },
    { country: 'Slovenia', type: 'eu_vat', label: 'SI VAT', format: 'SI12345678', regex: /^SI\d{8}$/ },
    { country: 'South Africa', type: 'za_vat', label: 'ZA VAT', format: '4123456789', regex: /^\d{10}$/ },
    { country: 'South Korea', type: 'kr_brn', label: 'KR BRN', format: '123-45-67890', regex: /^\d{3}-\d{2}-\d{5}$/ },
    { country: 'Spain', type: 'es_cif', label: 'ES CIF', format: 'A12345678', regex: /^[A-Z]\d{7}$/ },
    { country: 'Spain', type: 'eu_vat', label: 'ES VAT', format: 'ESA1234567Z', regex: /^ESA\d{7}[A-Z]$/ },
    { country: 'Sweden', type: 'eu_vat', label: 'SE VAT', format: 'SE123456789123', regex: /^SE\d{12}$/ },
    { country: 'Switzerland', type: 'ch_vat', label: 'CH VAT', format: 'CHE-123.456.789 MWST', regex: /^CHE-\d{3}\.\d{3}\.\d{3}\s?MWST$/ },
    { country: 'Taiwan', type: 'tw_vat', label: 'TW VAT', format: '12345678', regex: /^\d{8}$/ },
    { country: 'Thailand', type: 'th_vat', label: 'TH VAT', format: '1234567891234', regex: /^\d{13}$/ },
    { country: 'Turkey', type: 'tr_tin', label: 'TR TIN', format: '0123456789', regex: /^\d{10}$/ },
    { country: 'Ukraine', type: 'ua_vat', label: 'UA VAT', format: '123456789', regex: /^\d{9}$/ },
    { country: 'United Arab Emirates', type: 'ae_trn', label: 'AE TRN', format: '123456789012345', regex: /^\d{15}$/ },
    { country: 'United Kingdom', type: 'eu_vat', label: 'XI VAT', format: 'XI123456789', regex: /^XI\d{9}$/ },
    { country: 'United Kingdom', type: 'gb_vat', label: 'GB VAT', format: 'GB123456789', regex: /^GB\d{9}$/ }
  ];
  