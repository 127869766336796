import React, { useState, useEffect, useRef, useMemo } from 'react';
import {
  Container, Typography, TextField, Button, Box, Tooltip, IconButton,
  List, ListItem, ListItemText, Divider, Hidden, Drawer, CssBaseline, MenuItem
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import MenuIcon from '@mui/icons-material/Menu';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import axios from 'axios';
import LogoImage from '../logo-cubedai.png';
import CodeMirror from '@uiw/react-codemirror';
import 'codemirror/lib/codemirror.css';
import 'codemirror/theme/material-darker.css';
import 'codemirror/mode/javascript/javascript';
import { abcdef } from '@uiw/codemirror-theme-abcdef';
import { javascript } from '@codemirror/lang-javascript';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import PersonIcon from '@mui/icons-material/Person';
import MyCustomIcon from '../icon.png';
import Menu from '@mui/material/Menu';
import { useNavigate } from 'react-router-dom';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import LogoutIcon from '@mui/icons-material/Logout';

import APIDocumentation from './APIDocumentation';
import DeployModelPage from './Deploy';
import UserProfilePage from './UserProfile';
import useCheckToken from './useCheckToken';


const darkTheme = createTheme({
  palette: {
    mode: 'dark',
    background: {
      default: '#121212',
      paper: '#1E1E1E',
    },
    text: {
      primary: '#ffffff',
      secondary: '#aaaaaa',
    },
  },
});

let threadId;

function Workspace() {
  const [script, setScript] = useState('');
  const [scriptModified, setScriptModified] = useState(false);
  const [models, setModels] = useState([]);
  const memoizedModels = useMemo(() => models, [models]);
  const [apiLogs, setApiLogs] = useState('No logs yet.');
  const [showSecret, setShowSecret] = useState(false);
  const baseendpoint = "https://api.cubedai.com";
  const apiendpoint = "http://api.cubedai.com/v1/endpoint";
  const [httpendpoint, setHttpEndpoint] = useState('');
  const [secretKey, setSecretKey] = useState('');
  const [error, setError] = useState('');
  const accessToken = "998567Xgh7Xzy01GhD632oY9";
  const [copySuccess, setCopySuccess] = useState(false);
  const [copySuccess_secret, setCopySuccess_secret] = useState(false);
  const [mobileOpen, setMobileOpen] = useState(false);
  const [inputNumber, setInputNumber] = useState(1);
  const [chatInput, setChatInput] = useState('');
  const [messages, setMessages] = useState([]);
  const [loading, setLoading] = useState(false);
  const messagesEndRef = useRef(null);
  const inputRef = useRef(null);
  const [selectedModel, setSelectedModel] = useState(null);
  const [toggleState, setToggleState] = useState(true); //toggle button for defaul model
  const [predictNumber, setPredictNumber] = useState(1);
  const [inputText, setInputText] = useState(''); // New state for "Input Text"
  const [frequency, setFrequency] = useState(''); // New state for "Frequency"
  const navigate = useNavigate();
  const [selectedSection, setSelectedSection] = useState('Workspaces');
  const [workspaces, setWorkspaces] = useState([]);
  const [selectedWorkspace, setSelectedWorkspace] = useState('');
  const [workspaceId, setWorkspaceId] = useState('');
  const [createdWorkspaceId, setCreatedWorkspaceId] = useState('');
  const [logs, setLogs] = useState('No logs yet.')

  const [lastFour, setLastFour] = useState('');
  const [firstFour, setFirstFour] = useState('');

  useCheckToken(); // Call the hook to check the token

  useEffect(() => {
    workspaceIdChecker();
  }, [workspaceId])

  const workspaceIdChecker = async () => {
    try {
      if (workspaceId !== createdWorkspaceId) {
        setSecretKey('');
      }
    } catch (error) {
      throw error;
    }
  }

  useEffect(() => {
    scrollToBottom();
    inputRef.current?.focus();
  }, [messages]);

  const handleAddWorkspace = async () => {
    const newWorkspace = prompt("Enter workspace name");
    if (newWorkspace) {
      const token = localStorage.getItem('token');
  
      try {
        const response = await axios.post(
          `${baseendpoint}/workspace/create`,
          { title: newWorkspace },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        setSecretKey(response.data.secretKey);
      
        setCreatedWorkspaceId(response.data.createdWorkspace._id);
        setWorkspaces([...workspaces, response.data.createdWorkspace.title]);
        handleWorkspaceClick(newWorkspace)
      } catch (error) {
        const errorMessage = error.response.data.message;
        setError('Cannot create workspace>>>', errorMessage);
        toast.error('Error: ' + errorMessage, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } finally {
        setLoading(false);
      }
    }
  };

  const fetchWorkspace = async (title) => {
    setLogs('...')
    const token = localStorage.getItem('token');
    try {
      const response = await axios.get(`${baseendpoint}/workspace/fetch`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          title: title,
        },
      });
  
      const data = await response.data;
      setWorkspaceId(data._id);
      setHttpEndpoint(`${apiendpoint}?workspaceId=${data._id}`);
      setScript(data.decode_function);
      setLastFour(data.last_four_digits);
      setFirstFour(data.first_four_digits);

      if (data.model_name){
        setSelectedModel(data.model_name);
        handleLogs(data.model_name);
        
      } else{
        setSelectedModel(null)
        setLogs('No Logs available')
      }
      
    } catch (error) {
      toast.error('Error activating decoder function: ' + error.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      console.error('Error fetching workspace:', error);
    }
  };

  const handleWorkspaceClick = (workspace) => {
    fetchWorkspace(workspace);
    setSelectedWorkspace(workspace);
  };

  const handleDeleteWorkspace = async (workspaceToDelete) => {
    const confirmed = window.confirm(`Are you sure you want to delete "${workspaceToDelete}" workspace?`);
    if (confirmed) {
      const token = localStorage.getItem('token');
      try {
        const response = await fetch(`${baseendpoint}/workspace/delete`, {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ title: workspaceToDelete }),
        });
  
        const result = await response.json();
        if (response.ok) {
          // Remove workspace from UI after successful deletion
          const updatedWorkspaces = workspaces.filter(workspace => workspace !== workspaceToDelete);
          setWorkspaces(updatedWorkspaces);
        } else {
          console.error(result.message);
        }
      } catch (error) {
        console.error('Failed to delete workspace:', error);
      }
    }
  };

  const handleButtonClick = async () => {
    try {
      const token = localStorage.getItem('token');
      const decodedFunction = new Function(script)();  
      const payload = {
        decode_function: script,
        title: selectedWorkspace,
      };
  
      const response = await fetch(`${baseendpoint}/workspace/update_decoder`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(payload),
      });
  
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(`${errorData.message} \n Status: ${response.status}`);
      }
  
      const result = await response.json();
  
      if (response.ok) {
        toast.success('Decoder function activated!', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
  
      setScriptModified(false);
    } catch (error) {
      console.error('Error activating decoder function:', error);
      toast.error('Error activating decoder function: ' + error.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  const handleSubmitClick = async () => {
    try {

      const token = localStorage.getItem('token');
      const payload = {
        model_name: selectedModel,
        title: selectedWorkspace,
      };
  
      const response = await fetch(`${baseendpoint}/workspace/update_model`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(payload),
      });
  
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(`${errorData.message} \n Status: ${response.status}`);
      }
  
      const result = await response.json();
  
      if (response.ok) {
        toast.success('Model is saved!', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
  
      setScriptModified(false);
    } catch (error) {
      console.error('Error saving model:', error);
      toast.error('Error saving model: ' + error.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  
  const handleTimestampClick = async () => {
    try {
      const token = localStorage.getItem('token');
  
      const payload = {
        time_series_forecasting: true,
        input_parameter: inputText,
        frequency: frequency,
        lags: inputNumber,
        horizon: predictNumber
      };
  
      const response = await fetch(`${baseendpoint}/workspace/update_timestamp`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(payload),
      });
  
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(`${errorData.message} \n Status: ${response.status}`);
      } 
  
      const result = await response.json();
  
      if (response.ok) {
        toast.success('Timestamp parameters updated!', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
  
      setScriptModified(false);
    } catch (error) {
      console.error('Error Timestamp parameters updated:', error);
      toast.error('Error Timestamp parameters updated: ' + error.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await fetch(`${baseendpoint}/workspace/list`, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        });

        const workspaces = await response.json();
        const workspaceTitles = workspaces.map(workspace => workspace.title);
        setWorkspaces(workspaceTitles);
      } catch (error) {
        console.error('Error:', error);
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };
  
    fetchData();
  }, []);
  
  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await fetch(`${baseendpoint}/data/fetch`, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        });
  
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
  
        const data = await response.json();

      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };
  
    fetchData();
  }, []);

  useEffect(() => {
    const fetchModels = async () => {
      try {
        const response = await fetch(`${baseendpoint}/deployed-models/list-inservice`, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        });

        const result = await response.json();
        setModels(result);
        
      } catch (error) {
        console.error('Error fetching models:', error);
      }
    };

    fetchModels();
  }, []);
  
  

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const getModelStatusColor = (status) => {
    switch (status) {
        case 'InService':
            return 'green';
        case 'Failed':
            return 'red';
        case 'Paused':
              return 'blue';
        default:
            return 'orange';
    }
};

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  const toggleShowSecret = () => {
    setShowSecret(!showSecret);
  };

  const getMaskedSecretKey = () => {
    if (!secretKey || secretKey.trim() === '') {
      return firstFour + '****...***'+lastFour;
    }else{
      return showSecret ? secretKey : secretKey.replace(/./g, '*');
    }
    
  };

  const handleLogout = () => {
    localStorage.removeItem('token');
    navigate('/');
  };

  
  const handleLogs = async (model_name) => {
      try {
        const response1 = await fetch(`${baseendpoint}/deployed-models/logs`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
          body: JSON.stringify({
            model_name: model_name,
            
          }),
        });
  
        if (!response1.ok) {
          throw new Error(`We had problem in fetching logs!`);
        }
    
        // Await response1.text() to get the resolved text content
        const text = await response1.text();
        setLogs(text)

      } catch (error) {
        setError('An error occurred while fetching logs.');
      } 
    };

    const handleopenLogs = async (model) => {
      try {
        const response1 = await fetch(`${baseendpoint}/deployed-models/logs`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
          body: JSON.stringify({
            model_name: model.model_name,
          }),
        });
    
        if (!response1.ok) {
          throw new Error(`We had a problem in fetching logs!`);
        }
    
        // Await response1.text() to get the resolved text content
        const text = await response1.text();
    
        // Create a Blob from the text and a URL for the Blob
        const blob = new Blob([text], { type: 'text/plain' });
        const url = URL.createObjectURL(blob);

        // Open the Blob URL in a new browser tab/window
        window.open(url, '_blank');
      } catch (error) {
        console.error(error);
        setError('An error occurred while fetching logs.');
      }
    };
    

   
  const drawerWidth = 240;
  
  const sidebar = (
    <Drawer
    variant="permanent"
    sx={{
      width: drawerWidth,
      flexShrink: 0,
      '& .MuiDrawer-paper': {
        width: drawerWidth,
        boxSizing: 'border-box',
        overflowX: 'hidden',
        backgroundColor: darkTheme.palette.background.paper,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between' // This will push the logout button to the bottom
      },
    }}
  >
    <Box
      sx={{
        overflow: 'hidden',
        width: drawerWidth,
        textAlign: 'center',
        padding: '16px 0'
      }}
    >
      <img
        src={LogoImage}
        alt="My App Logo"
        style={{
          maxWidth: '90%',
          height: 'auto'
        }}
      />
    </Box>
    <Divider />
    <List disablePadding sx={{ flexGrow: 1 }}>
      {['Workspaces', 'Deployed Models', 'Documentation', 'User Profile'].map((text) => (
        <ListItem
          button
          key={text}
          selected={selectedSection === text}
          onClick={() => {
            setSelectedSection(text);
            if (text === 'Workspaces') {
              setSelectedWorkspace(null);
            }
          }}
        >
          <ListItemText primary={text} />
        </ListItem>
      ))}
    </List>
    <Divider />
    <Box sx={{ padding: '16px', textAlign: 'center' }}>
      <IconButton
        onClick={handleLogout} // Function to handle logout
        color="primary"
        sx={{ color: darkTheme.palette.text.primary }}
      >
        <LogoutIcon />
      </IconButton>
    </Box>
  </Drawer>
  );
  
  const renderWorkspace = () => {
  
    return (
      <Box>
        <Typography variant="h6" gutterBottom component="div">
          {selectedWorkspace}
        </Typography>
  
        {/* HTTP Endpoint */}
        <Box marginTop={4} display="flex" alignItems="center" marginBottom={2}>
          <Typography variant="h8" gutterBottom component="div">
            <span style={{ 
              backgroundColor: '#d3d3d3', 
              borderRadius: '4px', 
              padding: '2px 6px', 
              boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)', 
              color: 'black'
            }}>
              HTTP
            </span> {httpendpoint}
          </Typography>
          <Tooltip title="Copy to Clipboard">
            <IconButton
              color={copySuccess ? "success" : "default"}
              onClick={() => {
                navigator.clipboard.writeText(httpendpoint).then(() => {
                  setCopySuccess(true);
                  setTimeout(() => setCopySuccess(false), 2000);
                }).catch(() => {
                  setCopySuccess(false);
                });
              }}
            >
              <ContentCopyIcon />
            </IconButton>
          </Tooltip>
        </Box>
  
        {/* Secret Key */}
        <Box marginTop={2} display="flex" alignItems="center" marginBottom={2}>
          <Typography variant="h8" gutterBottom component="div">
            <span style={{ 
              backgroundColor: '#d3d3d3', 
              borderRadius: '4px', 
              padding: '2px 6px', 
              color: 'black',
              boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)' 
            }}>
              Secret Key
            </span> {getMaskedSecretKey()}
          </Typography>
  
          {/* Conditionally render the icons based on the presence of secretKey */}
          {secretKey && secretKey.trim() !== '' && (
            <>
              <Tooltip title="Copy to Clipboard">
                <IconButton
                  color={copySuccess_secret ? "success" : "default"}
                  onClick={() => {
                    navigator.clipboard.writeText(secretKey).then(() => {
                      setCopySuccess_secret(true);
                      setTimeout(() => setCopySuccess_secret(false), 2000);
                    }).catch(() => {
                      setCopySuccess_secret(false);
                    });
                  }}
                >
                  <ContentCopyIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title={showSecret ? "Hide Secret" : "Show Secret"}>
                <IconButton onClick={toggleShowSecret}>
                  {showSecret ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </Tooltip>
            </>
          )}
          
        </Box>
        {secretKey && secretKey.trim() !== '' &&(
          
          <p style={{
            color: '#d4f7d4',
            backgroundColor: 'rgba(34, 139, 34, 0.2)', // Dark green with transparency
            padding: '15px',
            borderRadius: '8px',
            maxWidth: '600px',
            lineHeight: '1.6',
            fontSize: '1rem',
            border: '1px solid #228b22' // Forest green border for emphasis
          }}>
            Please save this secret key somewhere safe and accessible. For security reasons, you won't be able to view it again through your Workspace after reloading page. If you lose this secret key, you'll need to create a new workspace.
          </p>
        )}
        
  
        {/* Model Selection */}
        <Box>
          <Typography variant="h6" gutterBottom component="div" marginTop={7}>
            Choose deployed model
          </Typography>
          <Box marginTop={2} marginBottom={2} display="flex" alignItems="center">
            <Box sx={{ minWidth: 200 }}>
              <TextField
                select
                label="Choose Model"
                value={selectedModel || ''}
                onChange={(event) => setSelectedModel(event.target.value)}
                variant="outlined"
                size="small"
                fullWidth
                sx={{
                  '& .MuiInputBase-root': {
                    backgroundColor: darkTheme.palette.background.paper,
                    color: darkTheme.palette.text.primary,
                  },
                }}
              >
                {models && models.length > 0 ? (
                  models.map((model) => (
                    <MenuItem key={model.model_name} value={model.model_name}>
                      {model.model_name}
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem disabled>No models available</MenuItem>
                )}
              </TextField>
            </Box>
          </Box>
          <Box marginTop={2} display="flex" alignItems="center">
            <Button onClick={handleSubmitClick} variant="contained" color="primary">
              Submit
            </Button>
          </Box>
        </Box>
  
        {/* Logs Section */}
        <Box marginTop={4}>
          <Typography variant="h6" gutterBottom component="div">
            Logs
          </Typography>
          <TextField
            value={logs}
            multiline
            rows={6}
            fullWidth
            variant="outlined"
            InputProps={{
              readOnly: true,
              sx: {
                backgroundColor: darkTheme.palette.background.paper,
                color: darkTheme.palette.text.primary,
              },
            }}
          />
        </Box>
      </Box>
    );
  };
  

  const RenderModels = () => {
    const [models, setModels] = useState([]);
    const [anchorEl, setAnchorEl] = useState(null);
    const [selectedModel, setSelectedModel] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
  
    const token = localStorage.getItem('token'); // Get the token from localStorage
  
    // Fetch models from the backend
    const fetchModels = async () => {
      try {
        setLoading(true);
        const response = await fetch(`${baseendpoint}/deployed-models/list`, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`, // Include the token in the Authorization header
          },
        });
        if (response.ok) {
          const data = await response.json();
          setModels(data);
        } else if (response.status === 404) {
          setError('No models found for this user.');
        } else {
          setError('Failed to fetch models. Please try again later.');
        }
      } catch (error) {
        setError('An error occurred while fetching models.');
      } finally {
        setLoading(false);
      }
    };

    useEffect(() => {
      const fetchModelStatus = async () => {
        try {
          const response = await fetch(`${baseendpoint}/deployed-models/check-status`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
          });
  
          const result = await response.json();
          setModels(result);
          console.log(result)
        } catch (error) {
          console.error('Error fetching model statuses:', error);
          setError('Failed to fetch model statuses');
        }
      };
  
      const intervalId = setInterval(fetchModelStatus, 20000);
  
      fetchModelStatus();
  
      return () => clearInterval(intervalId);
      
    }, []);  
  
    useEffect(() => {
      fetchModels();
    }, []);
  
    // Handle menu actions
    const handleClick = (event, model) => {
      setAnchorEl(event.currentTarget);
      setSelectedModel(model);
    };
  
    const handleClose = () => {
      setAnchorEl(null);
      setSelectedModel(null);
    };
  
    // Handle model deletion
    const handleDelete = async (model) => {
      const confirmed = window.confirm(`The workspaces use this model will be affected. Are you sure you want to delete model?`);
      if (confirmed){
        try {
          const response = await fetch(`${baseendpoint}/deployed-models/delete`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({
              job_name: model.job_name,
            }),
          });
    
          if (response.ok) {
            const data = await response.json();
    
            await fetchModels();
          } else if (response.status === 400) {
            setError('Failed to delete the model. Invalid request.');
          } else {
            setError('Failed to delete the model. Server error.');
          }
        } catch (error) {
          setError('An error occurred while deleting the model.');
        } finally {
          handleClose();
        }
      }
      
    };


    const handlePause = async (model) => {
      try {
        const response = await fetch(`${baseendpoint}/deployed-models/pause`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            job_name: model.job_name,
          }),
        });
  
        if (response.ok) {
          const data = await response.json();
  
          await fetchModels();
        } else if (response.status === 400) {
          setError('Failed to pause the model. Invalid request.');
        } else {
          setError('Failed to pause the endpoint. Server error.');
        }
      } catch (error) {
        setError('An error occurred while pausing the endpoint.');
      } finally {
        handleClose();
      }
    };

    const handleActivate = async (model) => {
      try {
      const response = await fetch(`${baseendpoint}/user/payment/information`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });
  
      // Handle 404 without logging to console
      if (!response.ok) {
        if (response.status === 404) {
          // Display a toast if no payment information is found
          toast.error('No payment information found', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } else {
          // Handle other types of errors if needed
          toast.error(`Unexpected error: ${response.status}`, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
          
         
        return; // Exit early if there is an error with the payment information
      }
  
      const data = await response.json();
      if (data.balance < 10) {
        toast.error('Insufficient Balance', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        return; // Exit if balance is insufficient
      }

      try {
        const response = await fetch(`${baseendpoint}/deployed-models/reactivate`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            job_name: model.job_name,
          }),
        });
  
        if (response.ok) {
          const data = await response.json();
  
          await fetchModels();
        } else if (response.status === 400) {
          setError('Failed to activate the endpoint. Invalid request.');
        } else {
          setError('Failed to activate the endpoint. Server error.');
        }
      } catch (error) {
        setError('An error occurred while activating the endpoint.');
      } finally {
        handleClose();
      }
  
      
    } catch (error) {
      // Handle any other errors outside of 404 without logging to console
      toast.error('An error occurred while checking payment information. Please try again.', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
      
    };
  
    const handleDeployModel = () => {
      setSelectedSection('DeployModel'); // Navigate to the deploy page
    };
  
    return (
      <Box>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: 2 }}>
          <Typography variant="h6" gutterBottom>
            Deployed Models
          </Typography>
          <Button variant="contained" color="primary" onClick={handleDeployModel}>
            Deploy Model
          </Button>
        </Box>
  
        {error && (
          <Typography color="error" gutterBottom>
            {error}
          </Typography>
        )}
  
        {loading ? (
          <Typography variant="h6">Loading...</Typography>
        ) : models.length === 0 ? (
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            sx={{ height: '300px' }}
          >
            <Typography variant="h6" color="textSecondary" mt={2}>
              No models available. Deploy your first model.
            </Typography>
          </Box>
        ) : (
          <List>
          {models.map((model) => (
            <ListItem
              key={model.model_name}
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                border: '1px solid #ccc',
                borderRadius: 4,
                padding: 1,
                marginBottom: 1,
                backgroundColor: '#333',
                transition: 'background-color 0.2s',
                '&:hover': {
                  backgroundColor: '#444',
                },
              }}
            >
              <ListItemText 
                primary={model.model_name} 
                primaryTypographyProps={{
                  sx: { color: 'white' },
                }} 
              />
              
              <Typography
                variant="body2"
                sx={{
                  marginRight: 2,
                  color: getModelStatusColor(model.status),
                  backgroundColor: 'white',
                  paddingX: 1,
                  paddingY: 0.25,
                  borderRadius: 2,
                  display: 'inline-block',
                }}
              >
                {model.status}
              </Typography>
              
              <IconButton
                onClick={(event) => {
                  event.stopPropagation(); // Prevents parent click handler
                  handleClick(event, model);
                }}
                sx={{
                  backgroundColor: 'lightgray',
                  color: 'black',
                  '&:hover': {
                    backgroundColor: 'gray',
                  },
                }}
              >
                <MoreVertIcon />
              </IconButton>
              
              {(model.status === 'InService' || model.status === 'Paused' || model.status === 'Failed' ) && (
                <Menu
                  anchorEl={anchorEl}
                  open={Boolean(anchorEl) && selectedModel?.model_name === model.model_name}
                  onClose={handleClose}
                >
                  {model.status === 'InService' && [
                    <MenuItem key="pause" onClick={() => handlePause(model)}>Pause</MenuItem>,
                    <MenuItem key="delete" onClick={() => handleDelete(model)}>Delete</MenuItem>,
                    <MenuItem key="logs" onClick={() => handleopenLogs(model)}>Get Logs</MenuItem>
                  ]}
                  {model.status === 'Paused' && [
                    <MenuItem key="activate" onClick={() => handleActivate(model)}>Activate</MenuItem>,
                  ]}
                  {model.status === 'Failed' && [
                    <MenuItem key="delete" onClick={() => handleDelete(model)}>Delete</MenuItem>,
                    <MenuItem key="logs" onClick={() => handleopenLogs(model)}>Get Logs</MenuItem>

                  ]}
                </Menu>
              )}
            </ListItem>
          ))}
        </List>

        )}
      </Box>
    );
  };

  const renderWorkspaceList = () => (
    <Box>
      
      <List>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: 2 }}>
          <Typography variant="h6" gutterBottom>
          Workspaces
          </Typography>
          <Button variant="contained" color="primary" onClick={handleAddWorkspace}>
          Add Workspace
          </Button>
        </Box>
  {workspaces.length === 0 ? (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      sx={{ height: '300px' }} // Adjust height as needed
    >
      <Typography variant="h6" color="textSecondary" mt={2}>
        No workspaces available. Create your first workspace.
      </Typography>
    </Box>
  ) : (
    workspaces.map((workspace, index) => (
      <ListItem 
        key={index}
        sx={{
          backgroundColor: '#333', // Dark gray background for list item
          borderRadius: 1, // Adds rounded corners
          marginY: 0.5, // Adds vertical margin between items
          padding: 1, // Adds padding within list item
          '&:hover': {
            backgroundColor: '#444', // Slightly lighter background on hover
          }
        }}
      >
        <ListItemText
          primary={workspace}
          onClick={() => handleWorkspaceClick(workspace)}
          sx={{
            cursor: 'pointer',
            color: '#FFF', // Light text color
            '&:hover': {
              color: '#BBB', // Lighter gray on hover
            },
          }}
        />
        <IconButton
          edge="end"
          aria-label="delete"
          onClick={() => handleDeleteWorkspace(workspace)}
          sx={{
            color: '#FFF', // Light color for icon
            '&:hover': {
              color: '#BBB', // Slightly darker color on hover
            },
          }}
        >
          <DeleteIcon />
        </IconButton>
      </ListItem>
    ))
  )}
</List>

    </Box>
  );

  return (
    <ThemeProvider theme={darkTheme}>
      <Box sx={{ display: 'flex', bgcolor: 'background.default', color: 'text.primary' }}>
        <CssBaseline />
        <Hidden smDown implementation="css">
          <Drawer
            variant="permanent"
            open
            sx={{
              width: drawerWidth,
              flexShrink: 0,
              '& .MuiDrawer-paper': { width: drawerWidth, boxSizing: 'border-box', bgcolor: 'background.paper' },
            }}
          >
            {sidebar}
          </Drawer>
        </Hidden>
        <Hidden smUp implementation="css">
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: 'flex', marginLeft: 'auto' }}
          >
            <MenuIcon />
          </IconButton>
          <Drawer
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true,
            }}
            sx={{
              '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth, bgcolor: 'background.paper' },
            }}
          >
            {sidebar}
          </Drawer>
        </Hidden>
        <Box
          component="main"
          sx={{ flexGrow: 1, p: 3, width: { sm: `calc(100% - ${drawerWidth}px)` }, bgcolor: 'background.grey', color: 'text.primary' }}
        >
          <Container maxWidth="lg">
            {selectedSection === 'Workspaces' && (
              <>
                {selectedWorkspace ? (
                  renderWorkspace() // Render the details for the selected workspace
                ) : (
                  renderWorkspaceList() // Show the list of workspaces
                )}
              </>
            )}
            {selectedSection === 'Deployed Models' && <RenderModels />}
            {selectedSection === 'Documentation' && APIDocumentation()}
            {selectedSection === 'DeployModel' && <DeployModelPage setSelectedSection={setSelectedSection} />}
            {selectedSection === 'User Profile' && <UserProfilePage />}
          </Container>
        </Box>
      </Box>
    </ThemeProvider>
  );
}

export default Workspace;
