import * as React from 'react';
import {
  Avatar,
  Button,
  CssBaseline,
  TextField,
  FormControlLabel,
  Checkbox,
  Link,
  Paper,
  Box,
  Grid,
  Typography,
} from '@mui/material';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import signupImage from '../cubedai-signup.png';
import LogoImage from '../logo-cubedai.png';

const defaultTheme = createTheme();

function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://cubedai.com/">
        CubedAI OÜ
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

export default function SignUpSide() {
  const navigate = useNavigate();
  const [isChecked, setIsChecked] = React.useState(false);
  const [checkboxError, setCheckboxError] = React.useState(false);
  const [password, setPassword] = React.useState("");
  const [passwordError, setPasswordError] = React.useState(false);
  const baseendpoint = "https://api.cubedai.com";

  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
    setCheckboxError(!event.target.checked);
  };

  const handlePasswordChange = (event) => {
    const newPassword = event.target.value;
    setPassword(newPassword);
    validatePassword(newPassword);
  };

  const validatePassword = (password) => {
    const minLength = 8;
    const hasUpperCase = /[A-Z]/.test(password);
    const hasLowerCase = /[a-z]/.test(password);
    const hasNumber = /[0-9]/.test(password);

    if (
      password.length >= minLength &&
      hasUpperCase &&
      hasLowerCase &&
      hasNumber 
    ) {
      setPasswordError(false);
    } else {
      setPasswordError(true);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const user = {
      name: data.get('name'),
      email: data.get('email'),
      password: data.get('password'),
    };

    if (passwordError) {
      toast.error("Please meet the password requirements.");
      return;
    }

    if (!isChecked) {
      setCheckboxError(true);
      return;
    }

    try {
      const response = await fetch(`${baseendpoint}/users/signup`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(user),
      });

      if (response.ok) {
        toast.success('Please check your Email box and Verify Email to finish Signup!', {
          position: "top-right",
          autoClose: 5000,
        });
        navigate('/');
      } else {
        const errorData = await response.json();
        toast.error(errorData.message || 'Signup failed', {
          position: "top-right",
          autoClose: 5000,
        });
      }
    } catch (error) {
      toast.error('Error: ' + error.message, {
        position: "top-right",
        autoClose: 5000,
      });
    }
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <Grid container component="main" sx={{ height: '100vh' }}>
        <CssBaseline />
        <Grid
          item
          xs={false}
          sm={4}
          md={7}
          sx={{
            backgroundImage: `url(${signupImage})`,
            backgroundRepeat: 'no-repeat',
            backgroundColor: (t) =>
              t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
        />
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <img src={LogoImage} alt="CubedAI Logo" style={{ width: '40%'}} />
            <Typography component="h1" variant="h5">
              Sign Up
            </Typography>
            <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1 }}>
              <TextField
                margin="normal"
                required
                fullWidth
                id="name"
                label="Name"
                name="name"
                autoComplete="name"
                autoFocus
              />
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
                value={password}
                onChange={handlePasswordChange}
                error={passwordError}
                helperText={
                  passwordError
                    ? "Password must be at least 8 characters long and include an uppercase letter, lowercase letter, and a number"
                    : ""
                }
              />
              <FormControlLabel
                control={
                  <Checkbox
                    color="primary"
                    checked={isChecked}
                    onChange={handleCheckboxChange}
                  />
                }
                label={
                  <span>
                    I have read and agree to the{' '}
                    <a href="https://cubedai.com/privacy-policy" target="_blank" rel="noopener noreferrer">
                      Privacy Policy
                    </a> 
                    {' '} and  {' '}
                    <a href="https://cubedai.com/term-conditions" target="_blank" rel="noopener noreferrer">
                       Terms and Conditions
                    </a>.
                  </span>
                }
              />
              {checkboxError && (
                <Typography variant="body2" color="error">
                  You must agree to the Privacy Policy to continue.
                </Typography>
              )}
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                Sign Up
              </Button>
              <Grid container>
                <Grid item>
                  <Link href="/" variant="body2">
                    {"Already have an account? Sign In"}
                  </Link>
                </Grid>
              </Grid>
              <Copyright sx={{ mt: 5 }} />
            </Box>
          </Box>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}
