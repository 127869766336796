import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Login from './components/Login';
import Signup from './components/Signup';
import Workspace from './components/Workspace';
import EmailVerificationSuccess from './components/EmailVerificationSuccess';
import InvalidVerification from './components/InvalidVerification';
import ForgetPassword from './components/forgetPassword';
import { ToastContainer } from 'react-toastify';
import ResetPassword from './components/resetPassword';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/workspace" element={<Workspace />} />
        <Route path="/verification-success" element={<EmailVerificationSuccess />}/>
        <Route path="/verification-invalid" element={<InvalidVerification />} />
        <Route path="/forget-password" element={<ForgetPassword />} />
        <Route path="/reset-password/:token" element={<ResetPassword />} />
      </Routes>
      <ToastContainer />
    </Router>
  );
}

export default App;
