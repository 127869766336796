import React from 'react';
import { Container, Typography, Box, Paper, IconButton, Tooltip } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

const APIDocumentation = () => {
  const handleCopyClick = (text) => {
    navigator.clipboard.writeText(text);
  };

  const codeBlock = (text) => (
    <Box
      component="div"
      sx={{
        display: 'flex',
        alignItems: 'center',
        backgroundColor: '#000000',
        color: '#ffffff',
        padding: '10px',
        borderRadius: '5px',
        marginBottom: '10px',
        border: '1px solid #ffffff',
        whiteSpace: 'pre-wrap'
      }}
    >
      <Typography variant="body2" sx={{ flexGrow: 1 }}>
        {text}
      </Typography>
      <Tooltip title="Copy to clipboard">
        <IconButton
          color="primary"
          onClick={() => handleCopyClick(text)}
        >
          <ContentCopyIcon sx={{ color: '#ffffff' }} />
        </IconButton>
      </Tooltip>
    </Box>
  );

  return (
    <Container maxWidth="md">
      <Box mt={4} mb={4}>
        <Typography variant="h2" gutterBottom>
          CubedAI API Documentation
        </Typography>

        <Paper elevation={3} style={{ padding: '20px' }}>
          <Box mb={4}>
            <Typography variant="h4" gutterBottom>
              Introduction
            </Typography>
            <Typography variant="body1" paragraph>
              You can interact with the API through HTTP requests from any language.
            </Typography>
          </Box>

          <Box mb={4}>
            <Typography variant="h4" gutterBottom>
              Authentication
            </Typography>
            
            <Typography variant="h5" gutterBottom>
              API keys
            </Typography>
            <Typography variant="body1" paragraph>
              The CubedAI API uses API keys for authentication. You can get an API key at a workspace or service account level.
            </Typography>
            <Typography variant="body1" paragraph>
              Remember that your API key is a secret! Do not share it with others or expose it in any client-side code (browsers, apps). Production requests must be routed through your own backend server where your API key can be securely loaded from an environment variable or key management service.
            </Typography>
            <Typography variant="body1" paragraph>
              All API requests should include your API key in an Authorization HTTP header as follows:
            </Typography>
            {codeBlock('Authorization: Bearer CUBEDAI_SECRET_KEY')}
          </Box>

          <Box mb={4}>
            <Typography variant="h4" gutterBottom>
              Organizations and projects (optional)
            </Typography>
            <Typography variant="body1" paragraph>
              Users can have multiple data sources with the same type of data projects through their legacy user API key, you can pass a header to specify which organization and project is used for an API request. Usage from these API requests will count as usage for the specified organization and project.
            </Typography>
            <Typography variant="body1" paragraph>
              To access the Default project in an organization, leave out the OpenAI-Project header.
            </Typography>
            {codeBlock('curl http://cubedai.com/v1/endpoint?workspaceId=$your_workspace_id \\\n  -H "Authorization: Bearer $CUBEDAI_SECRET_KEY"')}
          </Box>

          <Box mb={4}>
            <Typography variant="h4" gutterBottom>
              Making requests
            </Typography>
            <Typography variant="body1" paragraph>
              You can paste the command below into your terminal to run your first API request. Make sure to replace $CUBEDAI_API_KEY with your secret API key. You are using a legacy user key and can have multiple workspaces, that’s why you will also need to specify the Workspace Id.
            </Typography>
            {codeBlock(`curl http://cubedai.com/v1/endpoint?workspaceId=$your_workspace_id \\
  -H "Authorization: Bearer $CUBEDAI_SECRET_KEY" \\
  -H "projectID: $your_project_id" \\
  -H "Content-Type: application/json" \\
  -d '{
            "instances": [ ]
        }'`)}
            <Typography variant="body1" paragraph>
              This request sends the data to CubedAI platform and runs the prediction according to the given input data in “instances”. The data in instances must be according to the input data format used during the training of model. “workspaceId” is defined in user-created workspace and all data belonging for the same worspace must be sent using the same workspaceID. The data sent to the platform must be in JSON format.
            </Typography>
            <Typography variant="body1" paragraph>
              You should get a response back that resembles the following:
            </Typography>
            {codeBlock('{ "predictions": [3033.993896484375, 3213.98828125] }')}
            <Typography variant="body1" paragraph>
              If you have less number of historical data compared to “lags” defined in the workspace, you should get the following empty response:
            </Typography>
            {codeBlock('{ "predictions": [ ] }')}
            <Typography variant="body1" paragraph>
              
            </Typography>
          </Box>
        </Paper>
      </Box>
    </Container>
  );
};

export default APIDocumentation;
