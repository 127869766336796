import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const useCheckToken = () => {
  const navigate = useNavigate();

  const isTokenExpired = (token) => {
    if (!token) {
      return true;
    }

    try {
      const payload = JSON.parse(atob(token.split('.')[1]));
      const expirationTime = payload.exp * 1000; // Convert to milliseconds
      return Date.now() > expirationTime;
    } catch (error) {
      console.error('Invalid token format');
      return true;
    }
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      const token = localStorage.getItem('token');

      if (!token || isTokenExpired(token)) {
        localStorage.removeItem('token'); // Clear invalid/expired token
        navigate('/'); // Redirect to main page
      }
    }, 5000); // Check every 5 seconds (adjust as needed)

    return () => clearInterval(intervalId); // Clear interval on component unmount
  }, [navigate]);
};

export default useCheckToken;
