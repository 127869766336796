import React, { useState, useEffect } from 'react';
import { Box, Button, TextField, Typography,  CircularProgress,ToggleButton, ToggleButtonGroup, MenuItem, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle  } from '@mui/material';
import { UploadFile } from '@mui/icons-material';
import { SiPytorch, SiTensorflow, SiScikitlearn } from 'react-icons/si';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { toast } from 'react-toastify';
import axios from 'axios';

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
    primary: { main: '#3f51b5' },
    background: { default: '#121212', paper: '#1E1E1E' },
    text: { primary: '#ffffff', secondary: '#aaaaaa' },
  },
  components: {
    MuiButton: { styleOverrides: { root: { textTransform: 'none' } } },
    MuiInputBase: {
      styleOverrides: {
        root: {
          '& .MuiOutlinedInput-notchedOutline': { borderColor: '#3f51b5' },
          '&:hover .MuiOutlinedInput-notchedOutline': { borderColor: '#3f51b5' },
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': { borderColor: '#3f51b5' },
          color: '#ffffff',
        },
      },
    },
  },
});

const DeployModelPage = ({ setSelectedSection }) => {
  const [framework, setFramework] = useState('tf'); // Default selection
  const [region, setRegion] = useState('');
  const [locations, setLocations] = useState([]);
  const [instanceType, setInstanceType] = useState('');
  const [instanceTypes, setInstanceTypes] = useState([]); // Holds the list of instance types
  const [modelName, setModelName] = useState('');
  const [selectedFile, setSelectedFile] = useState(null); // Holds the selected file
  const [errors, setErrors] = useState({}); // Holds form field errors
  const [openDialog, setOpenDialog] = useState(false);
  const [loading, setLoading] = useState(false);
  const baseendpoint = "https://api.cubedai.com";

  // Handle framework change
  const handleFrameworkChange = (event, newFramework) => {
    if (newFramework !== null) setFramework(newFramework);
  };

  // Handle region change and fetch instance types
  const handleRegionChange = async (event) => {
    const selectedRegion = event.target.value;
    setRegion(selectedRegion);
    // Fetch instance types for the selected region
    try {
      const response = await fetch(`${baseendpoint}/location-types/${selectedRegion}`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
      if (response.ok) {
        const data = await response.json();
        setInstanceTypes(data); // Set instance types from the response
      } else {
        console.error('Failed to fetch instance types');
      }
    } catch (error) {
      console.error('Error fetching instance types:', error);
    }
  };

  // Handle instance type change
  const handleInstanceTypeChange = (event) => {
    setInstanceType(event.target.value);
  };

  // Handle file selection and validate file type
  const handleFileChange = (event) => {
    const file = event.target.files[0]; // Only allow one file
    if (file && file.name.endsWith('.tar.gz')) {
      setSelectedFile(file);
      setErrors((prevErrors) => ({ ...prevErrors, files: null })); // Clear error if file is valid
    } else {
      setSelectedFile(null);
      setErrors((prevErrors) => ({ ...prevErrors, files: 'Only .tar.gz files are allowed' }));
    }
  };

  // Handle model name change
  const handleModelNameChange = (event) => {
    setModelName(event.target.value);
  };

  // Handle form validation
  const validateForm = () => {
    let newErrors = {};
    if (!modelName) newErrors.modelName = 'Model name is required';
    if (!region) newErrors.region = 'Region is required';
    if (!instanceType) newErrors.instanceType = 'Instance type is required';
    if (!selectedFile) newErrors.files = 'Please upload a model file in .tar.gz format';

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0; // Return true if no errors
  };

  useEffect(() => {
    const fetchLocations = async () => {
      try {
        const response = await fetch(`${baseendpoint}/location-types/list`, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        });
        const data = await response.json(); 
        
        setLocations(data);
      } catch (error) {
        console.error('Error fetching locations:', error);
      }
    };

    fetchLocations();
  }, []);

  // Handle form submission (Deploy Model)
  const handleDeployModel = async () => {
    if (!validateForm()) {
      return; // Do not proceed if the form is invalid
    }
    
    const token = localStorage.getItem('token');
    
    if (!token) {
      toast.error('Authentication token is missing. Please log in.', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return; // Stop if there’s no token
    }
  
    try {
      const response = await fetch(`${baseendpoint}/user/payment/information`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });
  
      // Handle 404 without logging to console
      if (!response.ok) {
        if (response.status === 404) {
          // Display a toast if no payment information is found
          toast.error('No payment information found', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } else {
          // Handle other types of errors if needed
          toast.error(`Unexpected error: ${response.status}`, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
        return; // Exit early if there is an error with the payment information
      }
  
      const data = await response.json();
      if (data.balance < 10 || !data.balance) {
        toast.error('Insufficient Balance', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        return; // Exit if balance is insufficient
      }

      const response_models = await fetch(`${baseendpoint}/deployed-models/list`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`, // Include the token in the Authorization header
        },
      });
      
      const models = await response_models.json(); // Use the correct response object
        
        // Check if the model with the given modelName exists
      const modelExists = models.some(model => model.model_name === modelName);
      
      if (modelExists) {
          toast.error('Model name exists. Choose a new one.', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          return; // Exit if the model name already exists
      }
      


      const formData = new FormData();
      formData.append('model_name', modelName);
      formData.append('model_framework', framework);
      formData.append('region', region);
      formData.append('instance_type', instanceType);
      formData.append('modelFile', selectedFile);
  
      setLoading(true);
  
      try {
        const deployResponse = await axios.post(`${baseendpoint}/deployed-models/deploy`, 
          formData,
          {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          
        });
  
        setOpenDialog(true);
        
      } catch (deployError) {
        toast.error('Failed to deploy model. Please try again.', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } finally {
        setLoading(false);
      }
    } catch (error) {
      // Handle any other errors outside of 404 without logging to console
      toast.error('An error occurred while deploying model. Please try again.', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };
  
  

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setSelectedSection('Deployed Models');  // Switch to RenderModels after the dialog closes
  };

  return (
    <ThemeProvider theme={darkTheme}>
      <Box sx={{ padding: 4, maxWidth: 800, margin: '0 auto', backgroundColor: darkTheme.palette.background.default }}>
        <Typography variant="h4" gutterBottom color="white">
          Deploy AI Model
        </Typography>

        {/* Model Name */}
        <Box sx={{ marginBottom: 3 }}>
          <Typography variant="h6" gutterBottom color="primary">
            Model Name
          </Typography>
          <TextField
            fullWidth
            variant="outlined"
            placeholder="Enter model name"
            value={modelName}
            onChange={handleModelNameChange}
            error={!!errors.modelName}
            helperText={errors.modelName}
            sx={{ backgroundColor: darkTheme.palette.background.paper }}
          />
        </Box>

        {/* Model Framework */}
        <Box sx={{ marginBottom: 3 }}>
          <Typography variant="h6" gutterBottom color="primary">
            Model Framework
          </Typography>
          <ToggleButtonGroup
            value={framework}
            exclusive
            onChange={handleFrameworkChange}
            aria-label="Model Framework"
            sx={{
              display: 'flex',
              justifyContent: 'space-around',
              backgroundColor: darkTheme.palette.background.paper,
              borderRadius: 1,
              p: 1,
            }}
          >
            <ToggleButton value="tf" aria-label="TensorFlow" sx={{ borderRadius: 2 }}>
              <SiTensorflow size={40} />
            </ToggleButton>
            <ToggleButton value="torch" aria-label="PyTorch" sx={{ borderRadius: 2 }}>
              <SiPytorch size={40} />
            </ToggleButton>
            <ToggleButton value="scikit" aria-label="Scikit-Learn" sx={{ borderRadius: 2 }}>
              <SiScikitlearn size={40} />
            </ToggleButton>
          </ToggleButtonGroup>

          <Box mt={2}>
            {framework === "tf" && (
              <Typography variant="body1"
              style={{
                color: '#d4f7d4',
                backgroundColor: 'rgba(34, 139, 34, 0.2)', // Dark green with transparency
                padding: '15px',
                borderRadius: '8px',
                maxWidth: '600px',
                lineHeight: '1.6',
                fontSize: '1rem',
                border: '1px solid #228b22' // Forest green border for emphasis
              }}>
                We require TensorFlow &#61;&#61; 2.12.1 framework version. Models trained with earlier versions can cause failure in the deployment.
              </Typography>
            )}
            {framework === "torch" && (
              <Typography variant="body1"
              style={{
                color: '#d4f7d4',
                backgroundColor: 'rgba(34, 139, 34, 0.2)', // Dark green with transparency
                padding: '15px',
                borderRadius: '8px',
                maxWidth: '600px',
                lineHeight: '1.6',
                fontSize: '1rem',
                border: '1px solid #228b22' // Forest green border for emphasis
              }}>
                For best compatibility, we recommend to use PyTorch &#61;&#61; 2.0.0 for saving model to match the inference environment.
              </Typography>
            )}
            {framework === "scikit" && (
              <Typography variant="body1"
              style={{
                color: '#d4f7d4',
                backgroundColor: 'rgba(34, 139, 34, 0.2)', // Dark green with transparency
                padding: '15px',
                borderRadius: '8px',
                maxWidth: '600px',
                lineHeight: '1.6',
                fontSize: '1rem',
                border: '1px solid #228b22' // Forest green border for emphasis
              }}>
                We require Scikit-learn &#61;&#61; 1.2 framework version. Models trained with other versions can cause failure in the deployment.
              </Typography>
            )}
          </Box>
        </Box>

        {/* Model Files Upload */}
        <Box sx={{ marginBottom: 3 }}>
          <Typography variant="h6" gutterBottom color="primary">
            Upload Model File (Only .tar.gz)
          </Typography>
          <Button
            variant="outlined"
            component="label"
            startIcon={<UploadFile />}
            sx={{
              color: 'white',
              borderColor: 'white',
              '&:hover': { borderColor: darkTheme.palette.primary.main },
            }}
          >
            Upload File
            <input type="file" hidden onChange={handleFileChange} accept=".tar.gz" />
          </Button>
          {selectedFile && (
            <Typography color="primary" variant="body2">
              Selected file: {selectedFile.name}
            </Typography>
          )}
          {errors.files && (
            <Typography color="error" variant="body2">
              {errors.files}
            </Typography>
          )}
        </Box>

        {/* Region Selection */}
        <Box sx={{ marginBottom: 3 }}>
          <Typography variant="h6" gutterBottom color="primary">
            Region
          </Typography>
          <TextField
            select
            fullWidth
            variant="outlined"
            value={region}
            onChange={handleRegionChange}
            error={!!errors.region}
            helperText={errors.region}
            sx={{ backgroundColor: darkTheme.palette.background.paper }}
          >
            {locations.map((location) => (
              <MenuItem key={location} value={location}>
                {location}
              </MenuItem>
            ))}
          </TextField>
        </Box>

        {/* Instance Type Selection */}
        <Box sx={{ marginBottom: 3 }}>
          <Typography variant="h6" gutterBottom color="primary">
            Instance Type
          </Typography>
          <TextField
            select
            fullWidth
            variant="outlined"
            value={instanceType}
            onChange={handleInstanceTypeChange}
            error={!!errors.instanceType}
            helperText={errors.instanceType}
            sx={{ backgroundColor: darkTheme.palette.background.paper }}
          >
            {instanceTypes?.map((instance) => (
              <MenuItem key={instance} value={instance}>
                {instance}
              </MenuItem>
            ))}
          </TextField>
        </Box>

        {/* Deploy Button */}
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <Button
          variant="contained"
          color="primary"
          size="large"
          sx={{ borderRadius: 2, paddingX: 5, paddingY: 1.5 }}
          onClick={handleDeployModel}
          disabled={loading} // Disable button during loading
          startIcon={loading ? <CircularProgress size={24} /> : null}
        >
          {loading ? 'Deploying...' : 'Deploy Model'}
        </Button>
      </Box>
        <Dialog
          open={openDialog}  // Bind the dialog to the state
          onClose={handleCloseDialog}
          aria-labelledby="success-dialog-title"
          aria-describedby="success-dialog-description"
        >
          <DialogTitle id="success-dialog-title">{"Deployment Started"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="success-dialog-description">
              Your model deployment has been started. You will now be redirected to the Deployed Models page to check status.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
          <Button
              onClick={handleCloseDialog}
              sx={{
                backgroundColor: darkTheme.palette.primary.main,
                color: 'white',
                '&:hover': {
                  backgroundColor: darkTheme.palette.primary.dark,
                },
                padding: '8px 16px',
                borderRadius: '4px',
              }}
              autoFocus
            >
              OK
            </Button>
          </DialogActions>
        </Dialog>

      </Box>
    </ThemeProvider>
  );
};

export default DeployModelPage;
